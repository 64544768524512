import Document from "../../components/v2/Document";
import BlogDocument from "../../components/_pages/v2/blog/BlogDocument";

const Blog = () => (
	<Document
		title="Blog - Career Development"
		ogData={{
			title: "Stay Nimble. Career Development Blog Posts. - Stay Nimble",
			description: "Read our articles to help you develop your career and take it further.",
			image: "https://s3.eu-west-2.amazonaws.com/static.staynimble.co.uk/assets/open-graph/preview.jpg",
			url: "/blog/career-development/",
		}}
	>
		<BlogDocument category="career-development" pageTitle="Career Development" />
	</Document>
);

export default Blog;
